import React, { Fragment } from "react";

import Home from "./Home";
import Education from "./AuditTraining";
import AuditProcess from "./AuditProcess";
import Contact from "./Contact";
import MapMarker from "./MapMarker";
import Clients from "./Clients";
import Testimonials from "./Testimonials";
import SecurityAuditForm from "./AuditForm";

const sections = () => {
  return (
    <Fragment>
      <Home />
      <Education />
      <AuditProcess />
     <SecurityAuditForm />
      <Clients />
      <Testimonials />
      <Contact />
      {/* <MapMarker /> */}
    </Fragment>
  );
};

export default sections;
