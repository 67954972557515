import React from "react";
import AliceCarousel from "react-alice-carousel";

// data
import { clients } from "./clients";

// components
import Section from "../../components/Section";

const Clients = () => {
  return (
    <Section id="clients" color>
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Our </span>Clients
          </h2>
          <p className="section-subtitle mr-auto ml-auto">
            Discover the companies that Extropy.io us for building their tools and applications.
          </p>
        </div>
        <div className="section-content">
          <AliceCarousel responsive={{ 0: { items: 1 }, 1024: { items: 4 } }}>
            {clients.map((client) => (
              <img src={client.img} alt={client.alt} className="clients-logo" />
            ))}
          </AliceCarousel>
        </div>
      </div>
    </Section>
  );
};

export default Clients
