import React from "react";

import { Modal, ModalHeader, ModalBody } from "reactstrap";

export const CookiePolicy = ({ modal, toggle }) => {
  return (
    <Modal fullscreen size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader
        close={
          <button className="close" onClick={toggle}>
            ×
          </button>
        }
      >
        Cookie Policy
      </ModalHeader>
      <ModalBody>
        <h4>Cookies</h4>
        <p>
          Cookies are small files that are stored on your computer or mobile device by a website’s server and are unique
          to your web browser. If you choose to Decline then your experience won't be limited.
        </p>
        <h4>Policy</h4>
        <p>
          Through Google Analytics we use cookies to gather information on an aggregated level to apply some statistics
          on site traffic. This can contain some geographic and time-related information about website visitors.
        </p>
        <p>
          Information from the cookies is not shared or stored and if you choose to decline then no information is
          gathered.
        </p>
        <p>You can update your preferences at any time on this page.</p>
        <h4>Privacy</h4>
        <p>
          You can restrict or block cookies using your browser settings. Alternatively, you can visit
          www.internetcookies.org , which contains comprehensive information on how to do this on a wide variety of
          browsers and devices. You will find general information about cookies and details on how to delete cookies
          from your device.
        </p>
        Contact If you have any questions about this cookie policy or our use of cookies, please contact us.
      </ModalBody>
    </Modal>
  );
};
