import React, { useEffect, useRef, useState } from 'react';
import eth from '../../assets/img/chainLogos/eth.png';
import base from '../../assets/img/chainLogos/base.png';
import mina from '../../assets/img/chainLogos/mina.png';
import starknet from '../../assets/img/chainLogos/starknet.png';
import binance from '../../assets/img/chainLogos/binance.png';

import DottedLine from './DottedLine';

const BlockchainProtocolLogos = () => {
  const [lines, setLines] = useState([]);
  const logoRefs = useRef([]);

  const logos = [
    { src: eth, alt: 'Ethereum' },
    { src: base, alt: 'Base' },
    { src: mina, alt: 'Mina' },
    { src: starknet, alt: 'Starknet' },
    { src: binance, alt: 'Binance' }
  ];

  useEffect(() => {
    const updateLines = () => {
      const newLines = [];
      logoRefs.current.forEach((logo, index) => {
        if (logo && index < logoRefs.current.length - 1) {
          const nextLogo = logoRefs.current[index + 1];
          if (nextLogo) {
            const rect1 = logo.getBoundingClientRect();
            const rect2 = nextLogo.getBoundingClientRect();
            newLines.push({
              x1: rect1.right,
              y1: rect1.top + rect1.height / 2,
              x2: rect2.left,
              y2: rect2.top + rect2.height / 2,
            });
          }
        }
      });
      setLines(newLines);
    };

    updateLines();
    window.addEventListener('resize', updateLines);
    return () => window.removeEventListener('resize', updateLines);
  }, []);

  return (
    <div style={{ 
      display: 'flex', 
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
    }}>
      {logos.map((logo, index) => (
        <>
        <img
          key={index}
          ref={el => logoRefs.current[index] = el}
          src={logo.src}
          alt={logo.alt}
          style={{
            width: '60px',
            height: '60px',
            objectFit: 'contain'
          }}
        />
        {
            index !== (logos.length-1) && (
                <DottedLine />
            )
        }
        </>
      ))}
    </div>
  );
};

export default BlockchainProtocolLogos;