import React from 'react';

const DottedLine = ({ length = 50, color = '#000' }) => {
  return (
    <div
      style={{
        width: typeof length === 'number' ? `${length}px` : length,
        borderBottom: `2px dotted ${color}`,
        margin: '0 10px',
        display: 'inline-block'
      }}
    />
  );
};

export default DottedLine;