import React from "react";

// components
import Section from "../../components/Section";

const contact = () => {
  return (
    <Section id="contact" color>
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Contact </span>Us
          </h2>
          <div className="section-subtitle mr-auto ml-auto">
            <p>
              Looking for support for your next Blockchain  project, auditing your smart contracts or training? Do
              you have questions about our services?
            </p>
            <p> Feel free to contact us.</p>
          </div>
        </div>
        <div className="section-content">
          <div className="row text-center">
            <div className="col-md-3 col-lg-3 mr-auto ml-auto">
              <i style={{ fontSize: "2.5rem" }} className="fas fa-phone-alt icon-secondary-1 my-3" />
              <h4>+44 (0) 1865 338228</h4>
            </div>
            <div className="col-md-3 col-lg-3 mr-auto ml-auto">
              <i style={{ fontSize: "2.5rem" }} className="fas fa-envelope icon-complement my-3" />
              <h4>info@extropy.io</h4>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default contact;
