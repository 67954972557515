// Clients logo
import appliedBlockchain from "../../assets/img/clients/appliedblockchain.svg";
import bankOfCanada from "../../assets/img/clients/bankofcanada.svg";
import cevt from "../../assets/img/clients/cevt.svg";
import dacx from "../../assets/img/clients/dacx.svg";
import driveDeal from "../../assets/img/clients/drivedeal.svg";
import innsBrook from "../../assets/img/clients/innsbrook.svg";
import investmentPunkAcademy from "../../assets/img/clients/investmentpunkacademy.svg";
import kilt from "../../assets/img/clients/kilt.svg";
import lacero from "../../assets/img/clients/lacero.svg";
import wob from "../../assets/img/clients/wob.svg";
import woolf from "../../assets/img/clients/woolf.svg";
import xain from "../../assets/img/clients/xain.svg";
import animalConcerts from "../../assets/img/clients/animalConcerts.png";
import whirlprotocol from "../../assets/img/clients/whirlprotocol.jpg";
import quantNetwork from "../../assets/img/clients/quantNetwork.png";
import floatProtocol from "../../assets/img/clients/floatProtocol.svg";
import pwn from "../../assets/img/clients/pwn.svg";
// clients slider


export const clients = [
  {
    img: pwn,
    alt: "PWN logo"
  },
  {
    img: floatProtocol,
    alt: "Float Protocol logo"
  },
  {
    img: quantNetwork,
    alt: "Quant Network logo"
  },
  {
    img: whirlprotocol,
    alt: "Whirl Protocol logo"
  },
  {
    img: animalConcerts,
    alt: "Animal Concerts logo"
  },
  {
    img: bankOfCanada,
    alt: "Bank Of Canada logo",
  },
  {
    img: xain,
    alt: "Xain logo",
  },
  {
    img: cevt,
    alt: "CEVT logo",
  },
  {
    img: dacx,
    alt: "DACX logo",
  },
  {
    img: lacero,
    alt: "Lacero logo",
  },
  {
    img: appliedBlockchain,
    alt: "Applied Blockchain logo",
  },
  {
    img: wob,
    alt: "Work On Blockchain logo",
  },
  {
    img: investmentPunkAcademy,
    alt: "Investment Punk Academy logo",
  },
  {
    img: driveDeal,
    alt: "Drive Deal logo",
  },
  {
    img: innsBrook,
    alt: "INNS Brook logo",
  },
  {
    img: kilt,
    alt: "Kilt logo",
  },
  {
    img: woolf,
    alt: "Woolf logo",
  },
];
