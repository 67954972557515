import React from 'react';

const Testimonial = ({ children, author, companyLogo, companyName }) => {
  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '20px',
      maxWidth: '400px',
      margin: '0 auto',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      borderRadius: '8px',
      backgroundColor: '#f9f9f9',
    },
    quote: {
      fontSize: '18px',
      fontStyle: 'italic',
      textAlign: 'center',
      marginBottom: '20px',
      color: '#333',
    },
    author: {
      fontWeight: 'bold',
      marginBottom: '10px',
      color: '#555',
    },
    logoContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    logo: {
      width: '50px',
      height: '50px',
      objectFit: 'contain',
      marginRight: '10px',
    },
    companyName: {
      fontSize: '14px',
      color: '#777',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.quote}>
        {children}
      </div>
      <p style={styles.author}>{author}</p>
      <div style={styles.logoContainer}>
        <img src={companyLogo} alt={`${companyName} logo`} style={styles.logo} />
        <span style={styles.companyName}>{companyName}</span>
      </div>
    </div>
  );
};

export default Testimonial;