import React from 'react';

const AuditRequestButton = ({ onClick }) => {
  const buttonStyle = {
    backgroundColor: '#4d539a',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '5px',
    fontSize: '16px',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)',
    transition: 'background-color 0.3s ease',
  };

  const hoverStyle = {
    backgroundColor: '#3f4579',
  };

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    } else {
      const targetElement = document.getElementById("auditForm");

      if (targetElement) {
        const targetPosition = targetElement.getBoundingClientRect().top;
        const offsetPosition = targetPosition + window.pageYOffset - 50

  
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        });
      }
    }
  };

  return (
    <button
      style={buttonStyle}
      onMouseEnter={(e) => Object.assign(e.target.style, hoverStyle)}
      onMouseLeave={(e) => Object.assign(e.target.style, buttonStyle)}
      onClick={handleClick}
    >
      Request an Audit
    </button>
  );
};

export default AuditRequestButton;