import React from "react";

// components
import Section from "../../components/Section";

const Education = () => {
  return (
    <Section id="auditTraining">
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Education</span>
          </h2>
          <div className="section-subtitle mr-auto ml-auto">
            <p>
              Check our website foundation.extropy to learn more about our courses
            </p>
            <p>
              We teach different blockchain related topics, from ZK proofs to smart contract security and much more:
            </p>
            <a href="https://foundation.extropy.io/" target="_blank" rel="noopener noreferrer">foundation.extropy.io</a>
          </div>
        </div>
      </div>
    </Section >
  );
};

export default Education;
