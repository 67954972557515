import React, { Fragment } from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

// layout
import Header from "./Header";
import Footer from "./Footer";
import Sections from "../sections";

// Pages
import Audits from "../pages/Audits";
import AuditProcess from "../pages/AuditProcess";

const Layout = () => {
  return (
    <BrowserRouter>
      <Fragment>
        <Header />
        <main>
          <Routes>
            <Route path="/" element={<Sections />} />
            <Route path="audits" element={<Audits />} />
            { /*
              <Route path="auditProcess" element={<AuditProcess/>} />
            */}
          </Routes>
        </main>
        <Footer />
      </Fragment>
    </BrowserRouter>
  );
};

export default Layout;
