import React from 'react';

const section = props => {
  const sectionStyle = {
    backgroundColor: props.color ? '#f6f8fb' : 'transparent',
  };

  return <section id={props.id} style={sectionStyle}>{props.children}</section>;
};

export default section;
