import React from "react";

// layout
import Nav from "../Nav";

const header = () => {
  return (
    <div className="header">
      <Nav />
    </div>
  );
};

export default header;
