import React from 'react';

import BlockchainProtocolLogos from './ChainLogos';
import Section from "../../components/Section";
import AuditRequestButton from '../../components/RequestAuditButton';

const Home = () => {
  return (
    <Section id="home" color>
      <div className="container pt-2 pb-5">
        <div className="section-header pt-5 pb-5 text-center">
          <h2 className="section-title">
            <span>Our services</span>
          </h2>
          <div className="section-subtitle mr-auto ml-auto">
          Empowering the decentralized ecosystem with top-tier smart contract protection, innovative development resources, and expert-led blockchain education.
          <hr />
          Contact our team of experts to get a quote on your next audit, available on:
            </div>
        </div>
        <BlockchainProtocolLogos />
        <div style={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
          <AuditRequestButton />
        </div>
      </div>
    </Section>
  );
};

export default Home;
