const defiAuditsSummary = [
  {
    defi_project_name: "1inch",
    icon: "fa-check-circle",
    auditor: "Peppersec",
    summary: "Several medium and minor issues discovered. All issues were fixed successfully.",
    report_link:
      "https://github.com/peppersec/public-audit-reports/blob/master/reports_pdf/1inch-Protocol-audit-report.pdf",
    smart_contract_link: "https://etherscan.io/address/0x27c165eC92Bf98b00D4Af65228A342f84dA8Cf30",
  },
  {
    defi_project_name: "Aave",
    icon: "fa-check-circle",
    auditor: "OpenZeppelin",
    summary:
      "The audit was based on the pre-production protocol, therefore its not reflective of the deployed version. There were a number of issues raised at pre-production level.",
    report_link: "https://blog.openzeppelin.com/aave-protocol-audit/",
    smart_contract_link: "https://etherscan.io/address/0x5274891bec421b39d23760c04a6755ecb444797c#code",
  },
  {
    defi_project_name: "Akropolis",
    icon: "fa-check-circle",
    auditor: "CertiK",
    summary:
      "A manual review revealed no vulnerabilities with the codebase. High security standards. The team demonstrated consistency and reliability regarding code ethics and practices.",
    report_link: "https://github.com/akropolisio/sparta/tree/develop/audit",
    smart_contract_link: "https://etherscan.io/address/0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7",
  },
  {
    defi_project_name: "Almost One",
    icon: "fa-times-circle",
    auditor: "",
    summary: "We weren't able to find any audit reports.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x022057c3ef9166433750165bfda21d52988e0756#code",
  },
  {
    defi_project_name: "Atomic loans",
    icon: "fa-check-circle",
    auditor: "Consensys Diligence",
    summary:
      "The audit team evaluated that the system is secure, resilient, and working according to its specifications.",
    report_link: "https://github.com/ConsenSys/atomic-loans-audit-report-2019-07",
    smart_contract_link: "https://etherscan.io/address/0x10d2f250a30dc78f3b418730e6aae4c1cf695889#code",
  },
  {
    defi_project_name: "Augur",
    icon: "fa-check-circle",
    auditor: "Keythereum",
    summary: "Changes to improve several functions were made, which were noted in the report.",
    report_link: "https://github.com/AugurProject/augur-audits/blob/master/keythereum/keythereum-audit.md",
    smart_contract_link: "https://etherscan.io/address/0x221657776846890989a759ba2973e427dff5c9bb#code",
  },
  {
    defi_project_name: "Balancer Finance",
    icon: "fa-check-circle",
    auditor: "Trail Of Bits",
    summary:
      "The issues found during engagement were fixed or reasonably mitigated without increasing the code's complexity. The code follows a high quality software development standard and best practices. It has suitable architecture and is properly documented. Functions are small, with a clear purpose. Due to time constraints, only manual verification of essential properties related to token swapping was performed.",
    report_link: "https://docs.balancer.finance/protocol/security/audits",
    smart_contract_link: "https://etherscan.io/address/0xba100000625a3754423978a60c9317c58a424e3d#code",
  },
  {
    defi_project_name: "Bancor",
    icon: "fa-check-circle",
    auditor: "Arachnid",
    summary:
      "Well written code and readable. Participants should note that the contracts as authored for the crowdsale are not trustless, and depend on the good behaviour of Bancor. Bancor have stated that this is intentional, intended to allow them to respond to and remedy any issues that come up during the crowdsale and in early operation, and that manual oversight will be exchanged for more automated operation once they are confident the system is working as intended. The report recommends that all 'owner' addresses during this initial phase should be governed by a multisig, preferably with significant oversight and participation by nonaffiliated individuals.",
    report_link: "https://gist.github.com/Arachnid/c65fd1bd61a8e0294aef95a4808edc78",
    smart_contract_link: "https://etherscan.io/address/0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c#code",
  },
  {
    defi_project_name: "$Based",
    icon: "fa-times-circle",
    auditor: "",
    summary: "No audit report available.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x68A118Ef45063051Eac49c7e647CE5Ace48a68a5#code",
  },
  {
    defi_project_name: "BlockFi",
    icon: "fa-question-circle",
    auditor: "",
    summary:
      "No independent audit report, however on their site they say that they're developing an in-house security team.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x1f3B2fbCb49e7d729243921A8774087D59ef9A65#code",
  },
  {
    defi_project_name: "bZx",
    icon: "fa-check-circle",
    auditor: "CertiK",
    summary:
      "The report gave an overall finding of 'Very High Confidence'. The bZx team applied the auditor's recommendations. The report concludes that there were no further recommendations required.",
    report_link: "https://bzx.network/pdfs/BZRX_vBZRX_CertiK_Report_1_07_11_2020.pdf",
    smart_contract_link: "https://etherscan.io/address/0x56d811088235F11C8920698a204A5010a788f4b3#code",
  },
  {
    defi_project_name: "Coinlist",
    icon: "fa-question-circle",
    auditor: "",
    summary:
      "No independent audit report available, however on 13th August 2020 it was reported that they are doing a full technical audit.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x1A19A9B36265b8b1f8D7875A7EC8f6A78d7B3781#code",
  },
  {
    defi_project_name: "Compound",
    icon: "fa-check-circle",
    auditor: "Trail Of Bits (2020)",
    summary:
      "3 'Informational' severities found. Informational severities do not post an immediate risk, but are relevant to security best practices.",
    report_link: "https://compound.finance/docs/security",
    smart_contract_link: "https://etherscan.io/address/0xc00e94cb662c3520282e6f5717214004a7f26888#code",
  },
  {
    defi_project_name: "Cream.Finance",
    icon: "fa-times-circle",
    auditor: "",
    summary: "We weren't able to find any audit reports.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x2ba592F78dB6436527729929AAf6c908497cB200#code",
  },
  {
    defi_project_name: "Curve Finance",
    icon: "fa-check-circle",
    auditor: "Trail Of Bits (2020)",
    summary:
      "The report focused on arithmetic operations and common contract flaws. 7 issues reported and 1 recommendation. None of these issues were critical.",
    report_link: "https://www.curve.fi/audits",
    smart_contract_link: "https://etherscan.io/address/0xdf5e0e81dff6faf3a7e52ba697820c5e32d806a8#code",
  },
  {
    defi_project_name: "DeFiner",
    icon: "fa-question-circle",
    auditor: "",
    summary:
      "They have an internal security process. To view their process: https://blog.definer.org/how-does-definer-ensure-the-security-of-users-fund",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0xc6ec8b2f6a7912f6f16e5892140b25e875d13738#code",
  },
  {
    defi_project_name: "dydx",
    icon: "fa-check-circle",
    auditor: "OpenZeppelin Security",
    summary:
      "'Two high severity issues were found. Some changes were proposed to follow best practices and reduce the potential attack surface'. Well written code with extensive tests.",
    report_link: "https://docs.dydx.exchange/#vulnerability-disclosure-policy",
    smart_contract_link: "https://etherscan.io/address/0x6af6c791c869dfa65f8a2fa042fa47d1535bef25#code",
  },
  {
    defi_project_name: "Force.Protocol",
    icon: "fa-check-circle",
    auditor: "SlowMist Technology",
    summary:
      "The report highlighted some vulnerabilities, some of which had been fixed. A full breakdown of the vulnerabilities can be found in the Conclusion.",
    report_link:
      "https://medium.com/the-force-protocol/slowmist-technology-released-the-security-audit-results-of-fortube-smart-contract-security-16fb132784f5",
    smart_contract_link: "https://etherscan.io/address/0x1fcdce58959f536621d76f5b7ffb955baa5a672f#code",
  },
  {
    defi_project_name: "Instadapp",
    icon: "fa-check-circle",
    auditor: "Samczsun",
    summary:
      "'In total, 1 undetermined, 0 high, 3 medium, 4 low, and 2 informational findings were documented. Additionally, 9 recommendations were made.' 'InstaDApp resolved all undetermined, medium, and low severity findings and applied some of the recommendations. The remaining findings and recommendations were discussed at length during which compelling and satisfactory reasoning for why they were unaddressed were given.'",
    report_link: "https://docs.instadapp.io/audits/",
    smart_contract_link: "https://etherscan.io/address/0x3a306a399085f3460bbcb5b77015ab33806a10d5#code",
  },
  {
    defi_project_name: "iYearn Finance",
    icon: "fa-times-circle",
    auditor: "",
    summary: "Smart contract has been verified on Etherscan but we couldn't find an audit report.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x251b5c9eff2d9f04dc525f601e13293bfe40e4f5#code",
  },
  {
    defi_project_name: "Jackpool Finance",
    icon: "fa-check-circle",
    auditor: "",
    summary: "There are audit reports however they aren't written in English.",
    report_link: "https://jackpool.finance/",
    smart_contract_link: "https://etherscan.io/address/0xe352460Cc5C836FC063089Dcae4Bb96c5b2c82D1#code",
  },
  {
    defi_project_name: "Jupiter",
    icon: "fa-times-circle",
    auditor: "",
    summary: "We weren't able to find any audit reports or a website at the time.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x4B1E80cAC91e2216EEb63e29B957eB91Ae9C2Be8#code",
  },
  {
    defi_project_name: "JustSwap",
    icon: "fa-check-circle",
    auditor: "SlowMist",
    summary:
      "'There are 2 security issues found during the audit. After communication and feedback, with the Anyswap team, confirms that the risks found in the audit process are within thetolerable range.'",
    report_link: "https://justswap.io/docs/audit-report_en.pdf",
    smart_contract_link: "https://etherscan.io/address/0x9eD68f8e328b03a1dC82e97Ca7c785252659a681#code",
  },
  {
    defi_project_name: "Kava",
    icon: "fa-check-circle",
    auditor: "B-Harvest",
    summary: "Short term and long term recommendations were made by the auditor.",
    report_link:
      "https://github.com/b-harvest/kava-CDP-Auction-module-audit/blob/master/bharvest-kava-cdp-audit-report.pdf",
    smart_contract_link: "https://etherscan.io/address/0x08d1E0A7fBd4eDBF56D81Da21D1b0c9c95Fb507F#code",
  },
  {
    defi_project_name: "King Swap",
    icon: "fa-times-circle",
    auditor: "",
    summary: "At the time of reviewing, King Swap were in their pre sale stage and the contract wasn't deployed",
    report_link: "",
    smart_contract_link: "",
  },
  {
    defi_project_name: "Kyber Network",
    icon: "fa-check-circle",
    auditor: "Chain Security",
    summary:
      "'CHAINSECURITY is overall convinced of the soundness of KYBER.NETWORK’s project with regards to its design and its smart contract implementations. The smart contract test suite is exhaustive, and the smart contract code is of high quality. During the audit, CHAINSECURITY uncovered several issues worthy of KYBER.NETWORK’s attention, which have mostly been addressed. Overall, no significant security concern remains.'",
    report_link: "https://chainsecurity.com/security-audit/kyber-network/",
    smart_contract_link: "https://etherscan.io/address/0xdd974d5c2e2928dea5f71b9825b8b646686bd200#code",
  },
  {
    defi_project_name: "Maker",
    icon: "fa-check-circle",
    auditor: "Trail Of Bits",
    summary:
      "Clean implementation and good practices. Well written test code, therefore the code and behaviour reliability is high. Some suggestions were made for low severity errors.",
    report_link: "https://github.com/makerdao/mcd-security/blob/master/Audit%20Reports/TOB_MakerDAO_Final_Report.pdf",
    smart_contract_link: "https://etherscan.io/address/0x6b175474e89094c44da98b954eedeac495271d0f#code",
  },
  {
    defi_project_name: "Mooniswap",
    icon: "fa-check-circle",
    auditor: "Scott Bigelow",
    summary:
      "The overall code quality of the project is good and it is accompanied by unit tests. 2 issues which have been addressed.",
    report_link: "https://mooniswap.exchange/docs/mooniswap-audit-report-2.pdf",
    smart_contract_link: "https://github.com/CryptoManiacsZone/mooniswap/blob/master/contracts/Mooniswap.sol",
  },
  {
    defi_project_name: "Moonswap",
    icon: "fa-times-circle",
    auditor: "",
    summary:
      "Moonswap reported that they were looking for an auditor. The link on their site doesn't take you to an audit report.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x68a3637bA6E75c0f66B61A42639c4e9fCD3D4824#code",
  },
  {
    defi_project_name: "Mstable",
    icon: "fa-check-circle",
    auditor: "Consensys Diligence",
    summary:
      "Code quality is high and code is well documented. Test coverage is outstanding. Code adheres to best practices. There is some partially implemented code but does not necessitate any urgent updates. Other issues have been addressed.",
    report_link: "https://developers.mstable.org/security/security-audits",
    smart_contract_link: "https://etherscan.io/address/0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2#code",
  },
  {
    defi_project_name: "NUO Network",
    icon: "fa-question-circle",
    auditor: "",
    summary: "They have an audit report, however the report is not public.",
    report_link: "https://github.com/NuoNetwork/nuo-audited-contracts",
    smart_contract_link: "https://etherscan.io/address/0x78b37409628e10df0b661c6b205b872a4df8dd6e#code",
  },
  {
    defi_project_name: "Oasis",
    icon: "fa-question-circle",
    auditor: "",
    summary: "The Oasis team audit their contracts internally.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x794e6e91555438afc3ccf1c5076a74f42133d08d#code",
  },
  {
    defi_project_name: "Opyn Protection",
    icon: "fa-check-circle",
    auditor: "OpenZeppelin Security",
    summary:
      "One critical and two high severity issues were found. Some changes were proposed to follow best practices and reduce potential attack surface. The Opyn team has fixed the critical issue and implemented partial fixes and monitoring solutions for all high issues in their follow up commit.",
    report_link: "https://blog.openzeppelin.com/opyn-contracts-audit/",
    smart_contract_link: "https://etherscan.io/address/0x31f88266301b08631f9f0e33fd5c43c2a5d1e5b2#code",
  },
  {
    defi_project_name: "Phase",
    icon: "fa-time-circle",
    auditor: "",
    summary:
      "The site says that they intend to have routine auditing however this isn't mentioned in their road map up to December 2020.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0xd97f06eaef19ced6fd89095ec4819bf608ef485b#code",
  },
  {
    defi_project_name: "Riggn",
    icon: "fa-check-circle",
    auditor: "",
    summary:
      "No audit report for the smart contract as far as we could see. The contract creator has not yet verified and published the smart contract on Etherscan.io",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x05ec8497082d7815217816b42d24af40e2ed1dd3#code",
  },
  {
    defi_project_name: "Scorpion Swap",
    icon: "fa-times-circle",
    auditor: "",
    summary:
      "We weren't able to find any audit reports. At the time we reviewed the smart contract, it hadn't been verified on Etherscan",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x9F0e63066E31dE98137B9b7865B8edc4086e8E48#code",
  },
  {
    defi_project_name: "Spaghetti Pasta",
    icon: "fa-times-circle",
    auditor: "",
    summary: "We weren't able to find any audit reports.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0xE54f9E6Ab80ebc28515aF8b8233c1aeE6506a15E#code",
  },
  {
    defi_project_name: "SushiSwap",
    icon: "fa-times-circle",
    auditor: "PeckShield",
    summary:
      "https://github.com/peckshield/publications/blob/master/audit_reports/PeckShield-Audit-Report-SushiSwap-v1.0.pdf",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x6b3595068778dd592e39a122f4f5a5cf09c90fe2#code",
  },
  {
    defi_project_name: "Swerve",
    icon: "fa-times-circle",
    auditor: "",
    summary: "It's worth noting that Swerve is a fork of Curve Finance.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0xB8BAa0e4287890a5F79863aB62b7F175ceCbD433#code",
  },
  {
    defi_project_name: "StormSwap",
    icon: "fa-times-circle",
    auditor: "",
    summary: "We weren't able to find an audit report.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x01Afa0ec445508343e64cBD717491eF152Ef823F#code",
  },
  {
    defi_project_name: "Synthetix Exchange",
    icon: "fa-check-circle",
    auditor: "Iosiro",
    summary: "Several informational issues and one low risk issue. Overall, the implementation was of a high standard.",
    report_link: "https://docs.synthetix.io/contracts/audits/",
    smart_contract_link: "https://etherscan.io/address/0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f#code",
  },
  {
    defi_project_name: "Unidapp",
    icon: "fa-question-circle",
    auditor: "none",
    summary: "Unidapp relies on Uniswap's audit by Consensys for the security of their code.",
    report_link: "",
    smart_contract_link: "",
  },
  {
    defi_project_name: "Uniswap DEX",
    icon: "fa-check-circle",
    auditor: "dapp.org",
    summary:
      "1 medium severity issue and 1 low severity issue. 'Most of these improvements were adopted by the Uniswap development team.'",
    report_link: "https://uniswap.org/audit.html",
    smart_contract_link: "https://etherscan.io/address/0x68a5f10909da3ecb1460dc80473decd25468dd27#code",
  },
  {
    defi_project_name: "YAM Finance",
    icon: "fa-times-circle",
    auditor: "none",
    summary: "The site urges caution to users.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0xaba8cac6866b83ae4eec97dd07ed254282f6ad8a#code",
  },
  {
    defi_project_name: "YIncome",
    icon: "fa-times-circle",
    auditor: "",
    summary: "We weren't able to find an audit report.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x6de7C3F720677c1Dd9E92fDe3f22Db54b3d94dE1#code",
  },
  {
    defi_project_name: "Ybross.Finance",
    icon: "fa-times-circle",
    auditor: "",
    summary: "The site recommends that users do their own research and decide for themselves.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x1a90f0ebae806470a427999e48c433571da218fa#code",
  },
  {
    defi_project_name: "YFF Protocol",
    icon: "fa-times-circle",
    auditor: "",
    summary: "No audit report available.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0xd274d633e734af99c7d5880cf088b34c414938e5#code",
  },
  {
    defi_project_name: "Y FOX Finance",
    icon: "fa-times-circle",
    auditor: "",
    summary: "No audit report available.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/token/0x706CB9E741CBFee00Ad5b3f5ACc8bd44D1644a74",
  },
  {
    defi_project_name: "YFIX.Finance",
    icon: "fa-times-circle",
    auditor: "",
    summary:
      "No audit report available. At the time of writing, the contract creator hadn't verified or published the contract source code on Etherscan. Please check etherscan.io for an update.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x3D76840198b0DE6dc06774ffe1f690F23143dAA9#code",
  },
  {
    defi_project_name: "YF Tron / YFX",
    icon: "fa-times-circle",
    auditor: "",
    summary: "No audit report available.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x5F1f3c1BeC598360FB4c1e2d80F84b16a716A862#code",
  },
  {
    defi_project_name: "Yoju Finance",
    icon: "fa-times-circle",
    auditor: "",
    summary: "We couldn't find a website for Yoju Finance.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0xB339B2d4027aFE620DD8E5fDDFA6D85D5753B4Cc#code",
  },
  {
    defi_project_name: "YouEarn.Finance",
    icon: "fa-times-circle",
    auditor: "",
    summary: "No audit report available.",
    report_link: "",
    smart_contract_link: "https://etherscan.io/address/0x512f7cd373dfc21503efcf9e72c792f6a67b60cd#code",
  },
];

export default defiAuditsSummary;

{
  /* <p>
<b>Peppersec</b>
</p>
<p>Several medium and minor issues discovered.</p>
<p>All issues were fixed successfully.</p> */
}
