import React, { useState, useEffect } from "react";

import { Link as RouterLink } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";

// assets
import logo from "../../assets/img/extropy_logo.svg";

// data
import { menu } from "../navigation";
import AuditRequestButton from "../../components/RequestAuditButton";

const buttonStyle = {
  marginTop: "3px",
  marginLeft: "20px",
  padding: '10px 15px',
  fontSize: '16px',
  fontWeight: 'bold',
  color: 'white',
  backgroundColor: 'rgb(77, 83, 154)',
  border: 'none',
  borderRadius: '5px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
  transition: 'all 0.3s ease',
  height: "44px",
};

const Nav = () => {
  const [navClass, setNavClass] = useState("");
  const [toggeledNav, settoggeledNav] = useState(false);

  const toggleNav = () => {
    settoggeledNav(!toggeledNav);
  };

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navClass = "";
      if (window.scrollY >= 200) {
        navClass = "scrolled";
      }
      setNavClass(navClass);
    });
  }, []);
  return (
    <nav className={`navbar navbar-expand-md bg-light ${navClass}`}>
      <div className="container">
        <a className="navbar-brand" href="!#">
          <NavHashLink to="/">
            <img src={logo} alt="Extropy.io logo" style={{ width: 150 }} />
          </NavHashLink>
        </a>
        <div
          className={`navbar-toggler nav-icon ${(() => {
            if (toggeledNav) return "open";
            return "";
          })()}`}
          onClick={toggleNav}
        >
          <span />
          <span />
          <span />
        </div>

        <div
          className={`collapse navbar-collapse ${(() => {
            if (toggeledNav) return "show";
            return "";
          })()}`}
        >
          <ul className="navbar-nav ml-auto">
            {menu.map((item) => {
              if (item.type === "page") {
                return (
                  <li className="nav-item nav-link">
                    <RouterLink to={item.path}>
                      {item.title}
                    </RouterLink>
                  </li>
                )
              } else {
                return (
                  <li className="nav-item nav-link">
                    <NavHashLink to={"/#" + item.target} smooth={true} activeClassName="active">
                      <span>{item.title}</span>
                    </NavHashLink>
                  </li>
                )
              }
            })}
            <li style={{ marginLeft: '20px', marginTop: '4px' }}>
              <AuditRequestButton />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
